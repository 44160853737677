<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { useForm } from "@inertiajs/vue3";

defineProps({
});

const form = useForm({
    email: '',
    password: '',
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
}
</script>
<template>
    <GuestLayout>
        <Head title="Login" />

        <form @submit.prevent="submit">
            <v-row class="mt-16">
                <v-col class="mt-10">
                    <v-label color="grey" class="font-size-20">Please enter your details below to log in...</v-label>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field class="mb-3" name="email" label="Username *" v-model="form.email"
                        :error-messages="form.errors.email" outlined></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-text-field class="mb-0" name="password" label="Password *" v-model="form.password" hide-details
                        outlined :error-messages="form.errors.password" type="password">
                    </v-text-field>
                    <a  class="link" :href="route('password.request')"><small>Forgotten your password?</small></a>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="text-right">
                        <v-btn type="submit" class="text-transform-none" prepend-icon="far fa-right-to-bracket" color="primary">
                            Log in
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </form>
    </GuestLayout>
</template>

<style></style>
